import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// Imports for styling parts of the site.
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../fontawesome';
import { device } from '../components/device';
import Video from '../components/slices/video';

// Importing compnents used on this Page.
import Layout from '../components/layout';
import SEO from '../components/seo';
import ImageSeperator from '../components/imageseperator';

// Importing standalone CSS file.
import 'normalize.css';
import '../styles/global.css';

// Stlyes for the hero section of the page.
const HeroContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10vw;
  margin: 5vh 10vw 10vh 10vw;;
  height: auto;
  width: 80vw;
  top: 10vh;
  position: relative;
  z-index: 10;

  @media ${device.laptop} {
    display: grid;
    grid-template-areas: 'info' 'images';
    grid-gap: 0;
  }
`;

const HomeInfo = styled.div`
  height: 80vh;
  width: 35vw;

  @media ${device.laptop} {
    width: 80vw;
    grid-area: info;
    height: auto;
  }
`;

const HomeTitle = styled.h1`
  font-size: 4rem;
  margin: 0;
  border-bottom: 5px solid #353e4d;
  display: flex;
  align-items: flex-end;

  &>span {
    font-family: 'paintingwithchocolate';
    font-weight: 400;
    font-size: 5rem;

    @media ${device.laptop} {
      font-size: 4rem;
    }
  }

  @media ${device.laptop} {
    width: 80vw;
    font-size: 3rem;
  }
`;

const HomeServices = styled.div`
  margin: 0;
  margin-bottom: 2rem;

  &>ul {
    list-style-type: none;
    padding: 0;

    &>li {
      font-size: 2rem;
      line-height: 1.6;
    }
  }
`;

const HomeImages = styled.div`
  display: grid;
  grid-template-areas: 'imageTop imageTop imageTop' 'imageMiddleLeft imageMiddleRight imageMiddleRight' 'imageBottomLeft imageBottomCenter imageBottomRight';
  grid-template-columns: 10vw 20vw 10vw;
  height: 80vh;
  width: 35vw;
  grid-gap: 10px;

  @media ${device.laptop} {
    width: 80vw;
    grid-area: images;
    height: auto;
    grid-template-columns: 30vw 20vw 30vw;
    grid-template-areas: 'imageTop imageTop imageTop' 'imageMiddleLeft imageMiddleRight imageMiddleRight' 'imageBottomLeft imageBottomCenter imageBottomRight';
  }

  @media ${device.tablet} {
    grid-template-areas: 'imageTop imageTop imageTop' 'imageMiddleLeft imageMiddleRight imageMiddleRight' 'imageBottomLeft imageBottomLeft imageBottomRight';
  }
`;

const HomeImage = styled.img`
  object-fit: cover;
  height: 20vh;
`;

const HomeImageTop = styled(HomeImage)`
  grid-area: imageTop;
  height: 40vh;
  width: 41vw;

  @media ${device.laptop} {
    width: 80vw;
  }
`;

const HomeImageMiddleLeft = styled(HomeImage)`
  grid-area: imageMiddleLeft;
  width: 10vw;

  @media ${device.laptop} {
    width: 30vw;
  }
`;

const HomeImageMiddleRight = styled(HomeImage)`
  grid-area: imageMiddleRight;
  width: 30.5vw;

  @media ${device.laptop} {
    width: 49vw;
  }
`;

const HomeImageBottomLeft = styled(HomeImage)`
  grid-area: imageBottomLeft;
  width: 10vw;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);
  
  @media ${device.laptop} {
    width: 30vw;
  }
  
  @media ${device.tablet} {
    width: 52vw;
  }
`;

const HomeImageBottomMiddle = styled(HomeImage)`
  grid-area: imageBottomCenter;
  width: 20vw;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);
  
  @media ${device.laptop} {
    width: 20vw;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

const HomeImageBottomRight = styled(HomeImage)`
  grid-area: imageBottomRight;
  width: 10vw;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);

  @media ${device.laptop} {
    width: 28vw;
  }

  @media ${device.tablet} {
    width: 27.5vw;
  }
`;

// General Style Components

const SubSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4vw;
  text-align: center;

  @media ${device.tablet} {
    &>p {
      width: 80vw;
    }
  }
`;

const AboutSubSectionContainer = styled(SubSectionContainer)`
  align-items: flex-start;
  text-align: left;
  padding: 0.5rem;
  border-top: 1px solid #353e4d;

  @media ${device.tablet} {
    width: 75vw;
  }
`;

const PortfolioSubSectionContainer = styled(SubSectionContainer)`
  background-color: rgba(245,245,245,1);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: calc(100% + 100px);
    clip-path: polygon(0% 0%, 100% 50px, 100% calc(100% - 50px), 0% calc(100% - 50px));
    background-color: rgba(245,245,245,1);
    z-index: 1;
  }

  &>* {
    z-index: 1;
    position: relative;
  }

`;

// const PricingSubSectionContainer = styled(SubSectionContainer)`

// `;

const ContactSubSectionContainer = styled(SubSectionContainer)`
  background-color: rgba(245,245,245,1);
  position: relative;

  &>* {
    z-index: 1;
    position: relative;
  }
`;

const SubSectionTitle = styled.h2`
  border-bottom: 2px solid #353e4d;
`;

// Portfolio Styles.

const PortfolioAllContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5vh;

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }
`;

const PortfolioVideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

const PortfolioSeeMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  text-align: center;
  margin: 1rem;
  padding: 3rem 0;
  font-size: 1rem;

  width: 30vw;

  border-radius: 1rem;
  background-color: #353e4d;
  overflow: hidden;

  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);

  transition: 0.2s all;

  &:hover {
    transform: scale(0.95);
  }

  &>h3 {
    font-size: 1.5rem;
  }

  &>svg {
    font-size: 2rem;
    background-color: white;
    width: 5rem;
    margin: 1rem;
    padding: 1rem;
    border-radius: 50%;
    color: #353e4d;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);
  }

  @media ${device.laptop} {
    width: 70vw;
  }
  
  @media ${device.tablet} {
    width: 50vw;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    width: 75vw;
    flex-direction: column;
  }
`;

const PortfolioSeeMoreText = styled.h3`
  color: white;
  margin: 1rem;
`;

// Styles for the contact section.

const ContactMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem;
  margin-bottom: 0;

  &>a>svg {
    width: 2.5rem;
    padding: 1.5rem;
    border-radius: 25%;
    margin: 0.5rem;
    font-size: 3rem;
    background-color: #353e4d;
    transition: 0.2s all;
    color: white;

    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
              0 2px 2px rgba(0,0,0,0.05), 
              0 4px 4px rgba(0,0,0,0.05), 
              0 8px 8px rgba(0,0,0,0.05), 
              0 16px 16px rgba(0,0,0,0.05), 
              0 32px 32px rgba(0,0,0,0.05);
    
    &:hover {
      transform: scale(0.95);
    }
  };

  @media ${device.tablet} {
    margin-top: 1rem;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    homePage: prismic {
      allHome_pages {
        edges {
          node {
            logo
            portfoliosectiontext
            portfoliosectionseemore
            contactsectiontext
            aboutsectiontext
            imageseperatorportfoliopricing
            homeimagebottomleft
            homeimagebottommiddle
            homeimagebottomright
            homeimagemiddleleft
            homeimagemiddleright
            homeimagetop
            services
            sitetitle
          }
        }
      }
    }
  portfolio: prismic {
    allPortfoliopages {
      edges {
        node {
          body {
            ... on PRISMIC_PortfoliopageBodyVideo {
              type
              label
              primary {
                video
              }
            }
          }
        }
      }
    }
  }
}
`);

  const doc = data.homePage.allHome_pages.edges.slice(0, 1).pop();
  if (!doc) return null;

  useEffect(() => {
    window.prismic = {
      endpoint: 'https://myrmedia.cdn.prismic.io/api/v2'
    };
  });

  const portfolioVidArray = data.portfolio.allPortfoliopages.edges[0].node.body.slice(0, 4);

  return (
    <Layout>
      <SEO title="Home" />
      <Helmet>
        <meta charset="utf-8" />
        <title>Home</title>
        <link rel="canonical" href="myrmedia.ltd" />
      </Helmet>
      <HeroContainer id="home">
        <HomeImages>
          <HomeImageTop src={doc.node.homeimagetop.url} alt={doc.node.homeimagetop.alt} />
          <HomeImageMiddleLeft src={doc.node.homeimagemiddleleft.url} alt={doc.node.homeimagemiddleleft.alt} />
          <HomeImageMiddleRight src={doc.node.homeimagemiddleright.url} alt={doc.node.homeimagemiddleright.alt} />
          <HomeImageBottomLeft src={doc.node.homeimagebottomleft.url} alt={doc.node.homeimagebottomleft.alt} />
          <HomeImageBottomMiddle src={doc.node.homeimagebottommiddle.url} alt={doc.node.homeimagebottommiddle.alt} />
          <HomeImageBottomRight src={doc.node.homeimagebottomright.url} alt={doc.node.homeimagebottomright.alt} />
        </HomeImages>
        <HomeInfo>
          <HomeTitle>
            <span>{RichText.asText(doc.node.sitetitle).split(' ')[0]}</span>
            {RichText.asText(doc.node.sitetitle).split(' ')[1]}
          </HomeTitle>
          <HomeServices>{RichText.render(doc.node.services)}</HomeServices>
          <AboutSubSectionContainer id="about">
            <SubSectionTitle>About Us</SubSectionTitle>
            {RichText.render(doc.node.aboutsectiontext)}
          </AboutSubSectionContainer>
        </HomeInfo>
      </HeroContainer>

      {/* This is all of the subcontainers on the page. */}

      <PortfolioSubSectionContainer id="portfolio">
        <SubSectionTitle>Portfolio</SubSectionTitle>
        {RichText.render(doc.node.portfoliosectiontext)}

        <PortfolioAllContainer>
          <PortfolioVideoContainer>
            {portfolioVidArray.map((video, index) => (
              <Video slice={video} key={index} />
            ))}
          </PortfolioVideoContainer>

          <Link to="/portfolio">
            <PortfolioSeeMoreContainer>
              <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} />
              <PortfolioSeeMoreText>
                {RichText.asText(doc.node.portfoliosectionseemore)}
              </PortfolioSeeMoreText>
            </PortfolioSeeMoreContainer>
          </Link>
        </PortfolioAllContainer>


      </PortfolioSubSectionContainer>

      {/* Need to add props here to pass an image through. */}
      <ImageSeperator src={doc.node.imageseperatorportfoliopricing.url} alt={doc.node.imageseperatorportfoliopricing.alt} />

      <ContactSubSectionContainer id="contact">
        <SubSectionTitle>Contact Us</SubSectionTitle>
        {RichText.render(doc.node.contactsectiontext)}
        <ContactMethodContainer>
          <a href="mailto:michael@myrmedia.ltd" aria-label="Email"><FontAwesomeIcon icon={['fas', 'envelope-open']} /></a>
          <a href="https://www.instagram.com/myr_media/?hl=en" aria-label="Instagram"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
          <a href="https://www.facebook.com/MyrMedia0/#" aria-label="Facebook"><FontAwesomeIcon icon={['fab', 'facebook-square']} /></a>
        </ContactMethodContainer>
      </ContactSubSectionContainer>

    </Layout>
  );
};

export default IndexPage;
