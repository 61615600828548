import React from 'react';
import styled from 'styled-components';

// Places styles for site here using styled components.
const ImageContainer = styled.div`
  background-image: url(${(props) => props.src});
  height: 30vh;
  overflow-y: auto;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05), 
            0 16px 16px rgba(0,0,0,0.05), 
            0 32px 32px rgba(0,0,0,0.05);
`;

const ImageSeperator = ({ src }) => (
  <ImageContainer src={src} />
);
export default ImageSeperator;
