// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
  faPhoneSquare, faEnvelopeOpen, faArrowCircleRight
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add(
  faEnvelopeOpen,
  faPhoneSquare,
  faArrowCircleRight,
  faFacebookSquare,
  faInstagram
);
